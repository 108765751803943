import { render, staticRenderFns } from "./assessment.vue?vue&type=template&id=8d284bb2&scoped=true&"
import script from "./assessment.vue?vue&type=script&lang=js&"
export * from "./assessment.vue?vue&type=script&lang=js&"
import style0 from "./assessment.vue?vue&type=style&index=0&id=8d284bb2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d284bb2",
  null
  
)

export default component.exports